import $ from "jquery"
import imagesLoaded from "imagesloaded"

imagesLoaded.makeJQueryPlugin( $ )

export default function() {

	console.log("-- Loader initialized")

	// Add class loaded when fonts and logo have loaded
	$('.logotype').imagesLoaded({ background: true }, function(){

		$('body').addClass('ready')

	})

}
