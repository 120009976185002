import $ from "jquery"
import Dialog from "a11y-dialog"

export default function() {

	console.log("-- Modals initialized")

	var mainEl = document.getElementById('main')
	var privacyDialogEl = document.getElementById('privacy-dialog')
	var termsDialogEl = document.getElementById('terms-dialog')
	
	var privacyDialog = new Dialog(privacyDialogEl, mainEl)
	var termsDialog = new Dialog(termsDialogEl, mainEl)

	$('a.privacy').on('click', function(){

		privacyDialog.show()

		return false

	})

	$('a.terms').on('click', function(){

		termsDialog.show()

		return false

	})

}
