import $ from "jquery"

export default function() {

	console.log("-- Year initialized")

	var d = new Date();
	var n = d.getFullYear();

	$('.year').html(n)

}
