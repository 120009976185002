// --------------------------  vendor  -------------------------- //
import Modernizr 		from "./lib/modernizr-custom"
import WebFont 			from "webfontloader"
import $ 				from "jquery"

// --------------------------  custom modules  -------------------------- //
import * as Config 		from "./modules/config"

import loader 			from "./modules/loader"
import dialogs 			from "./modules/dialogs"
import subscribeForm 	from "./modules/subscribeForm"
import year 			from "./modules/year"

var BadMachine = {

	go: function() {

		var $body = $('body')

		loader()
		dialogs()
		subscribeForm()
		year()

	}

}

// --------------------------  attach jquery to window  -------------------------- //

window.jQuery = $

// --------------------------  init fonts  -------------------------- //

WebFont.load({
	custom: {
		families: ["AvenirNext-Regular"]
	}
})


$(document).ready( function() {

	// Kick everything off
	BadMachine.go()

})