import $ from "jquery"
import imagesLoaded from "imagesloaded"
import parsley from "parsleyjs"
import jQueryCookie from "jquery.cookie"

imagesLoaded.makeJQueryPlugin( $ )

export default function() {

	console.log("-- Subscribe form initialized")

	// Dismiss fixed notice

	var cookieName = 'newsletterNotice'
	var $newsletterNotice = $('.newsletter-notice')
	var $closeButton = $newsletterNotice.find('a.close')

	if ( $.cookie(cookieName) == 'hidden' ) {
		$newsletterNotice.hide()
	}

	$closeButton.on('click', function(ev){

		ev.preventDefault()

		$newsletterNotice.hide()

		$.cookie(cookieName, 'hidden', { expires: 7 })

	})


	// Actual subscribe form

	var $newsletterForm = $('.newsletter-form')
	var $responseWrapper = $('.response-wrapper')
	var $response = $responseWrapper.find('.response')

	if ( $newsletterForm.length > 0 ) {

		$newsletterForm.parsley().on('form:submit', function(){

			$response.html('').removeClass('show')

			$.ajax({
				type: 		$newsletterForm.attr('method'),
				url: 		$newsletterForm.attr('action'),
				data: 		$newsletterForm.serialize(),
				cache: 		false,
				dataType: 	'json',
				contentType: "application/json; charset=utf-8",
				error: 		function(err) { 
					console.log(err)
					alert("Could not connect to the registration server. Please try again later.")
				},
				success     : function(data) {
					console.log(data)
					if (data.result != "success") {
						// Something went wrong, do something to notify the user. maybe alert(data.msg);
						
					} else {
						// It worked, carry on...

					}

					$response.html(data.msg)
					$responseWrapper.addClass('show')
					
				}
			});

			return false;


		})


	}

}
